@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Inter;
  src: url('assets/fonts/Inter.otf') format('opentype');
}

@font-face {
  font-family: Inter;
  src: url('assets/fonts/Inter-Italic.otf') format('opentype');
  font-style: italic;
}

@font-face {
  font-family: Inter;
  src: url('assets/fonts/Inter-Thin.otf') format('opentype');
  font-weight: 200;
}

@font-face {
  font-family: Inter;
  src: url('assets/fonts/Inter-ThinItalic.otf') format('opentype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: Inter;
  src: url('assets/fonts/Inter-Light.otf') format('opentype');
  font-weight: 300;
}

@font-face {
  font-family: Inter;
  src: url('assets/fonts/Inter-LightItalic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: Inter;
  src: url('assets/fonts/Inter-Medium.otf') format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: Inter;
  src: url('assets/fonts/Inter-MediumItalic.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: Inter;
  src: url('assets/fonts/Inter-SemiBold.otf') format('opentype');
  font-weight: 600;
}

@font-face {
  font-family: Inter;
  src: url('assets/fonts/Inter-SemiBoldItalic.otf') format('opentype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: Inter;
  src: url('assets/fonts/Inter-Bold.otf') format('opentype');
  font-weight: 700;
}

@font-face {
  font-family: Inter;
  src: url('assets/fonts/Inter-BoldItalic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: Inter;
  src: url('assets/fonts/Inter-ExtraBold.otf') format('opentype');
  font-weight: 800;
}

@font-face {
  font-family: Inter;
  src: url('assets/fonts/Inter-ExtraBoldItalic.otf') format('opentype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: Inter;
  src: url('assets/fonts/Inter-Black.otf') format('opentype');
  font-weight: 900;
}

@font-face {
  font-family: Inter;
  src: url('assets/fonts/Inter-BlackItalic.otf') format('opentype');
  font-weight: 900;
  font-style: italic;
}

html {
  font-family: Inter;
  font-feature-settings: 'tnum' off, 'lnum' on, 'zero' off, 'ss01' on;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
}

@layer utilities {
  .card-with-shadow-no-radius {
    @apply shadow bg-white;
  }
}

.mapboxgl-map {
  font: inherit !important;
}

.mat-icon.mat-icon-inline {
  @apply align-middle;
}

.mat-mdc-dialog-actions {
  @apply gap-x-2 justify-between !important;
}

.ngx-mat-tel-input-container > button {
  top: 24px !important;
  height: 24px !important;
}

.version-warning {
  transition: bottom 1s;
  z-index: 100000;
  bottom: -40px;
  width: 100%;
  position: fixed;
  display: flex;
  min-width: 100vw;
  max-width: 100vw;
  margin: 0;
  border-radius: 0;
  background-color: #feb300;
  align-items: center;
  opacity: 0;
}

.version-warning-text {
  justify-content: center;
  line-height: 24px;
  text-align: center;
  color: black;
  padding: 8px;
  width: 100%;
  font-size: 20px;
}

.wrong-version {
  bottom: 0;
  opacity: 1;
}

.sliced-gradient {
  background-image: linear-gradient(153deg, var(--tw-gradient-from) -8% 60%, 55%, var(--tw-gradient-to) 100%)
}
