/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
* Fork at: https://materialtheme.arcsine.dev/?c=YHBhbGV0dGU$YHByaW1hcnk$YF48IzQ0OGFmZiIsIj9lcjwjYzdkY2ZmIiwiO2VyPCMyZDZkZmZ$LCIlPmBePCNkN2RkZTAiLCI~ZXI8I2YzZjVmNiIsIjtlcjwjYzdjZmQzfiwid2Fybj5gXjwjZWY2MDQ4IiwiP2VyPCNmYWNmYzgiLCI7ZXI8I2U4NDQzMH4sIj9UZXh0PCMyZDJkMmUiLCI~PTwjZjlmOWZhIiwiO1RleHQ8I2ZmZmZmZiIsIjs9PCMyYzJjMmN$LCJmb250cz5bYEA8KC00IiwiZmFtaWx5PEludGVyfixgQDwoLTMiLCJmYW1pbHk8SW50ZXJ$LGBAPCgtMiIsImZhbWlseTxJbnRlcn4sYEA8KC0xIiwiZmFtaWx5PEludGVyfixgQDxoZWFkbGluZSIsImZhbWlseTxJbnRlcn4sYEA8dGl0bGUiLCJmYW1pbHk8SW50ZXJ$LGBAPHN1YiktMiIsImZhbWlseTxJbnRlcn4sYEA8c3ViKS0xIiwiZmFtaWx5PEludGVyfixgQDxib2R5LTIiLCJmYW1pbHk8SW50ZXJ$LGBAPGJvZHktMSIsImZhbWlseTxJbnRlcn4sYEA8YnV0dG9uIiwiZmFtaWx5PEludGVyfixgQDxjYXB0aW9uIiwiZmFtaWx5PEludGVyfixgQDxpbnB1dCIsImZhbWlseTxJbnRlciIsInNpemU$bnVsbH1dLCJpY29uczxGaWxsZWQiLCI~bmVzcz50cnVlLCJ2ZXJzaW9uPjEzfQ==
*/

@use '@angular/material' as mat;
@use '@ng-matero/extensions' as mtx;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.

// Fonts
@import 'https://fonts.googleapis.com/icon?family=Material+Icons';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500&display=swap');

$fontConfig: (
        display-4: mat.define-typography-level(112px, 112px, 300, 'Inter', normal),
        display-3: mat.define-typography-level(56px, 56px, 400, 'Inter', normal),
        display-2: mat.define-typography-level(45px, 48px, 400, 'Inter', normal),
        display-1: mat.define-typography-level(34px, 40px, 400, 'Inter', normal),
        headline: mat.define-typography-level(24px, 32px, 400, 'Inter', normal),
        title: mat.define-typography-level(20px, 32px, 500, 'Inter', normal),
        subheading-2: mat.define-typography-level(16px, 28px, 400, 'Inter', normal),
        subheading-1: mat.define-typography-level(15px, 24px, 500, 'Inter', normal),
        body-2: mat.define-typography-level(14px, 24px, 500, 'Inter', normal),
        body-1: mat.define-typography-level(14px, 20px, 400, 'Inter', normal),
        button: mat.define-typography-level(14px, 14px, 500, 'Inter', normal),
        caption: mat.define-typography-level(12px, 20px, 400, 'Inter', normal),
        input: mat.define-typography-level(inherit, 1.125, 400, 'Inter', normal)
);

// Foreground Elements

// Light Theme Text
$dark-text: #2d2d2e;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

// Dark Theme text
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

// Background config
// Light bg
$light-background: #f9f9fa;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-tooltip: lighten(#2c2c2c, 20%);
$dark-bg-alpha-4: rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12: rgba(#2c2c2c, 0.12);

// Compute font config
@include mat.core();

// Theme Config

body {
  --primary-color: #448aff;
  --primary-lighter-color: #c7dcff;
  --primary-darker-color: #2d6dff;
  --text-primary-color: #{$dark-primary-text};
  --text-primary-lighter-color: #{$dark-primary-text};
  --text-primary-darker-color: #{$light-primary-text};
}

$mat-primary: (
        main: #448aff,
        lighter: #c7dcff,
        darker: #2d6dff,
        contrast : (
                main: $dark-primary-text,
                lighter: $dark-primary-text,
                darker: $light-primary-text,
        )
);
$theme-primary: mat.define-palette($mat-primary, main, lighter, darker);


body {
  --accent-color: #d7dde0;
  --accent-lighter-color: #f3f5f6;
  --accent-darker-color: #c7cfd3;
  --text-accent-color: #{$dark-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$dark-primary-text};
}

$mat-accent: (
        main: #d7dde0,
        lighter: #f3f5f6,
        darker: #c7cfd3,
        contrast : (
                main: $dark-primary-text,
                lighter: $dark-primary-text,
                darker: $dark-primary-text,
        )
);
$theme-accent: mat.define-palette($mat-accent, main, lighter, darker);


body {
  --warn-color: #ef6048;
  --warn-lighter-color: #facfc8;
  --warn-darker-color: #e84430;
  --text-warn-color: #{$dark-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$light-primary-text};
}

$mat-warn: (
        main: #ef6048,
        lighter: #facfc8,
        darker: #e84430,
        contrast : (
                main: $dark-primary-text,
                lighter: $dark-primary-text,
                darker: $light-primary-text,
        )
);
$theme-warn: mat.define-palette($mat-warn, main, lighter, darker);
;

$theme: mat.define-light-theme((
        color: (
                primary: $theme-primary,
                accent: $theme-accent,
                warn: $theme-warn,
        ),
        typography: $fontConfig,
        density: 0,
));

// Theme Init
@include mat.all-component-themes($theme);
@include mtx.all-component-themes($theme);

// Specific component overrides, pieces that are not in line with the general theming

.mat-standard-chip {
  padding: .5em .85em;
  min-height: 2.5em;
}

.material-icons {
  font-size: 24px;
  font-family: 'Material Icons', 'Material Icons';

  .mat-badge-content {
    font-family: 'Inter';
  }
}

.mat-mdc-slide-toggle .mdc-form-field .mdc-switch {
  --mdc-switch-selected-track-color: #448aff;
  --mdc-switch-selected-handle-color: #448aff;
  --mdc-switch-selected-hover-track-color: #448aff;
  --mdc-switch-selected-hover-handle-color: #448aff;
  --mdc-switch-selected-focus-track-color: #448aff;
  --mdc-switch-selected-focus-handle-color: #448aff;
}
